<template>
  <div>
    <v-chip
      v-if="postal"
      :color="CLIENTE_DIRECCION.colores.postal"
      dark
      label
      small
      class="ml-1 mt-1"
    >
      Postal
    </v-chip>
    <v-chip
      v-if="fiscal"
      :color="CLIENTE_DIRECCION.colores.fiscal"
      dark
      label
      small
      class="ml-1 mt-1"
    >
      Fiscal
    </v-chip>
    <v-chip
      v-if="servicio"
      :color="CLIENTE_DIRECCION.colores.servicio"
      dark
      label
      small
      class="ml-1 mt-1"
    >
      De servicio
    </v-chip>
  </div>
</template>

<script>
import { CLIENTE_DIRECCION } from '@/utils/consts'

export default {
  props: {
    fiscal:{
      type: Boolean,
    },
    postal:{
      type: Boolean,
    },
    servicio:{
      type: Boolean,
    },
  },
  data () {
    return {
      CLIENTE_DIRECCION,
    }
  },
}
</script>
