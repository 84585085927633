import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import _ from '@/utils/lodash'

export default {
  search (apiFilter, search) {
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('calle', search)
        .addILike('num', search)
        .addILike('cp', search)
        .addILike('localidad', search)
        .addILike('provincia', search)
        .addILike('tvia_descripcion', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
  },
  async selectClienteDireccion (Vue, filter, search, sorter, page, idcliente, idclientePotencial) {
    let apiCalls = []
    const apiFilter = new APIFilter()
    const apiFilterRelacionado = new APIFilter()
    apiFilterRelacionado.addGT('estado', 0)
    if (idclientePotencial) {
      apiFilterRelacionado.addExact('idcliente_potencial', idclientePotencial)
      apiFilter.addExact('idcliente_potencial', idclientePotencial)
      apiCalls.push({ name: 'selectDatasetRelacionado', method: 'clientePotencial.select', params: { filter: apiFilterRelacionado } })
    } else {
      apiFilterRelacionado.addExact('idcliente', idcliente)
      apiFilter.addExact('idcliente', idcliente)
      apiCalls.push({ name: 'selectDatasetRelacionado', method: 'cliente.select', params: {filter: apiFilterRelacionado } })
    }
    apiFilter.addGT('estado', 0)
    this.search(apiFilter, search)
    apiCalls.push({
      name: 'selectClienteDireccion',
      method: 'clienteDireccion.select',
      params: {
        filter: apiFilter,
        page,
        sorter,
      }
    })
    const result =  await Vue.$api.batchCall(apiCalls)
    const datasetClienteDireccion = result.data.selectClienteDireccion.result.dataset
    const [datasetRelacionado] = result.data.selectDatasetRelacionado.result.dataset
    if (datasetRelacionado && datasetClienteDireccion) {
      // solo el cliente potencial tiene dirección de servicio
      if (idclientePotencial) {
        const indexDireccionServicio = _.findIndex(
          datasetClienteDireccion, {
            idcliente_direccion:  datasetRelacionado.idcliente_direccion_servicio
          }
        )
        // findIndex devuelve -1 si no lo encuentra
        if (indexDireccionServicio >= 0) {
          result.data.selectClienteDireccion.result.dataset[indexDireccionServicio].servicio = true
        }
      }
      const indexDireccionFiscal = _.findIndex(
        datasetClienteDireccion, {
          idcliente_direccion:  datasetRelacionado.idcliente_direccion
        }
      )
      if (indexDireccionFiscal >= 0) {
        result.data.selectClienteDireccion.result.dataset[indexDireccionFiscal].fiscal = true
      }
      const indexDireccionPostal = _.findIndex(
        datasetClienteDireccion, {
          idcliente_direccion:  datasetRelacionado.idcliente_direccion_postal
        }
      )
      if (indexDireccionPostal >= 0) {
        result.data.selectClienteDireccion.result.dataset[indexDireccionPostal].postal = true
      }
    }
    return [result.data.selectClienteDireccion.result.dataset, result.data.selectClienteDireccion.result.metadata]
  },
  async selectClienteDireccionRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idcliente_direccion', pks)
    const resp = await Vue.$api.call('clienteDireccion.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async deleteClienteDireccion (Vue, idclienteDireccion) {
    await Vue.$api.call('clienteDireccion.delete', { idcliente_direccion: idclienteDireccion })
  },
}
